$( window ).ready(function() {


    $('#site').on('click', '.toggle-ext',

      function( event ){

        $(this).find('.toggle').trigger('click');
      }
    );

    $('#site').on('click', '.toggle',

      function (event, triggered) {


        event.stopPropagation();

        var skip = false;
        var isTrigger = triggered || false;

        $this = $(this);

        try {
          skip = $this.data('skip') == 'true';
          if ( skip ) return;
        } catch (e){
        }

        var activeClass = $this.data('active');
        var propagation = $this.data('propagation');

        var toggleClass = $this.data('toggleclass');
        if( toggleClass == null ) toggleClass = 'hide';


        if ( !isTrigger && ( $this.hasClass('active') || $this.hasClass(activeClass) ) && $this.data('allowself') == false ) {
          return;
        }

        if ( ( typeof event != 'undefined' && $this.prop("tagName") != 'input' ) && ( typeof propagation == 'undefined' || propagation == null || propagation == false ) ) {
          event.preventDefault();
        }


        var start = $this.data('start');
        if (start) $(start).addClass(toggleClass);

        if (activeClass) $this.toggleClass(activeClass);

        var target = $this.data('target');
        var targets = $this.data('targets');

        var box = $(target);
        if( targets ) box = $(targets);

        box.toggleClass(toggleClass);

        if (box.hasClass(toggleClass)) {
          var hideCB = $this.data('hide-cb');
          if (hideCB != null) {
            eval(hideCB + '()');
          }
        }


        if ( typeof event.originalEvent == 'undefined' ) {
          return;
        }

        var brothers = $this.data('brothers');
        if ( brothers ) {
          var referrer = $this;
          $(brothers).each(
            function (index, ele) {

              if ( !$(ele).is( referrer[0] ) && ( $(ele).hasClass('active') || $(ele).hasClass(activeClass) ) ) $(ele).trigger('click', true);
            }
          );
        }


      }
    );

});