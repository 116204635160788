// assets/js/app.js

require('bootstrap');
require('../css/app.scss');
require('../lib/toggle.js');
require('../lib/owl/owl.carousel.min.js');
require('../lib/lity/lity.min.js');
require('./core.js');



const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything


// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');


//create global $ and jQuery variables
global.$ = global.jQuery = $;

//console.log('Hello Webpack Encore'); 

