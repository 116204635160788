

function openRequestedPopup(el) {

	var windowObjectReference;
	var strWindowFeatures = "menubar=no,location=no,status=no";
	
	var scrollbars = 'no';
	if ( $(el).data('popup-scrollbars') != null ){
		scrollbars = $(el).data('popup-scrollbars');
	}
	
	strWindowFeatures += ',scrollbars=' + scrollbars;
	
	if ( $(el).data('popup-width') != null ){
		strWindowFeatures += ',width=' + $(el).data('popup-width');
	}
	
	if ( $(el).data('popup-height') != null ){
		strWindowFeatures += ',height=' + $(el).data('popup-height');
	}
	
	console.log(strWindowFeatures);
	
	windowObjectReference = window.open($(el).attr('href'), "test", strWindowFeatures);
  
	windowObjectReference.focus();
	
}

$(document).ready(function(){

	
	$('.popup-link').on('click', function(e){
		
		openRequestedPopup(this);
		
		return false;
		
	});
	
});